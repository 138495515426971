h1,.h1,
h2,.h2,
h3,.h3,
h4,.h4,
h5,.h5,
h6,.h6 {

    .row + & {
        margin-top: $spacer5;
    }
}

h1 {
    margin-top: 4.3rem;
    margin-bottom: $spacer4;
    font-family: 'Roboto Slab', 'Times New Roman', serif;
    font-weight: 400;

    nav[aria-label="breadcrumb"] + div & {
        margin-top: 1rem;
    }
}

p + div.row {
    margin-top: $paragraph-margin-bottom * 1.5;
}

.altH2s {

    h2 {
        margin-top: $spacer5 * 1.5;
        font-weight: $font-weight-bold;
    }
}

.base-data-form {
    margin-top: $spacer5;
}
