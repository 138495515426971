.btn {
    text-transform: uppercase;
    letter-spacing: $letterSpacing;
}

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-hover-color: #fff;
    --bs-btn-active-color: #fff;
    --bs-btn-disabled-color: #000;
}

.btn-outline-primary {
    --bs-btn-hover-color: #fff;
    --bs-btn-active-color: #fff;
}

span.btn {

    &.disabled {
        @extend .disabled;
        cursor: not-allowed;
        pointer-events: unset !important;
    }
}