.formStepLinks {
    background-color: $light;

    a {
        text-decoration: none;
    }

    li {
        display: flex;
        align-items: center;
        border-bottom: 3px solid #fff;
    }

    .btn {
        flex-grow: 1;
        text-align: left;
    }

    .statusIcon {
        margin-right: 0.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.75rem;
        height: 1.75rem;
        border-radius: 100rem;
        background-color: $success;
        color: #fff !important;
    }

    .complete {
        background-color: desaturate(lighten($success, 60%), 30%);
        color: darken($success, 10%);
    }

    svg {
        width: 1rem !important;
        height: 1rem !important;
    }

    .current {
        font-weight: $font-weight-semibold;
        color: $body-color;
    }
}
