/* roboto-slab-regular - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 400;
    src: url($fontsPath + 'robotoslab/roboto-slab-v24-latin-regular.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
    url($fontsPath + 'robotoslab/roboto-slab-v24-latin-regular.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-slab-700 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 700;
    src: url($fontsPath + 'robotoslab/roboto-slab-v24-latin-700.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
    url($fontsPath + 'robotoslab/roboto-slab-v24-latin-700.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
