@mixin resetButtonElement {
    appearance: none;
    border: none;
    background-color: transparent;
    vertical-align: unset;
    font-size: unset;
    color: inherit;
    box-shadow: none;
    outline: 0;
    cursor: pointer;
}

.resetButtonElement {
    @include resetButtonElement();
}
