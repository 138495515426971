.files {
    @include list-unstyled();

    .fileItem {
        margin-bottom: $spacer1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $primary-100;
        border-radius: 0.5rem;
    }

    .fileName {
        padding: $spacer2 $spacer3;
        font-size: 105%;
        font-weight: $font-weight-light;
        color: $primary-800;
    }

    .fileBtn {
        height: unset;
        align-self: stretch;
        width: 2rem;
    }
}
