header {
    $navItemBorderColor: $gray-400;
    $navItemHoverColor: $gray-600;

    .navbar {
        padding-top: 0;
        padding-bottom: 0;

        @media (min-width: $grid-float-breakpointMin) {
            display: block;
        }

        > .container {

            @media (max-width: $grid-float-breakpointMax) {
                max-width: unset;
                padding-right: unset;
                border-bottom: 5px solid $primary; // as eea-website
            }

            @each $breakpoint in map-keys($navbar-heights) {
                @include media-breakpoint-up($breakpoint) {
                    height: map-get($navbar-heights, $breakpoint);
                }
            }
        }
    }

    .brandsAndSwitches {
        flex-grow: 1;
        display: flex;
        align-items: center;
        height: 100%;

        @media (min-width: $grid-float-breakpointMin) {
            padding-top: $navbar-padding-y;
            padding-bottom: $navbar-padding-y;
        }
    }

    .navbarRight {
        margin-left: auto;
        height: 100%;
        display: flex;

        @media (max-width: $grid-float-breakpointMax) {
            align-items: center;
        }

        @media (min-width: $grid-float-breakpointMin) {
            flex-direction: column;
        }
    }

    .navbar-brand {
        display: block;

        img {
            display: block;
            width: auto;
            height: 100%;
        }
    }

    .navbar-brand-left {
        height: 100%;

        @media (max-width: $grid-float-breakpointMax) {
            padding-top: 5px;
            padding-bottom: 5px;
        }

        @media (min-width: $grid-float-breakpointMin) {
            margin-left: -2px;
        }
    }

    .navbar-brand-right {
        height: 72.5%;

        @media (min-width: $grid-float-breakpointMin) {
            margin-top: auto;
            margin-right: -2px;
            margin-bottom: -4px;
        }

        img {

            @media (max-width: $grid-float-breakpointMax) {
                display: none;
            }
        }
    }

    .languageSwitcher {
        $paddingY: 0.17rem;
        $paddingX: 0.525rem;

        display: flex;
        justify-content: flex-end;

        @media (min-width: $grid-float-breakpointMin) {
            margin-top: -5px;
            margin-right: -9.5px;
        }

        .nav-item {

            &,
            &:last-child {
                border: none;
            }

            + .nav-item {
                border-left: 1px solid $gray-500;
            }
        }

        .nav-link {
            padding: $paddingY $paddingX;
            font-size: $font-size-base * 0.667;
            color: $gray-800;

            @media (max-width: $grid-float-breakpointMax) {
                padding: 6px 10px;
                font-size: $font-size-base;
            }

            &:hover {
                color: $link-color;
            }
        }

        .active {
            pointer-events: none;

            .nav-link {
                color: $text-muted;
            }
        }
    }

    .navbar-collapse {
        background-color: $gray-100; // as eea-website
        text-transform: uppercase;
        letter-spacing: $letterSpacing;

        @media (max-width: $grid-float-breakpointMax) {
            background-color: $navItemHoverColor;
        }

        @media (min-width: $grid-float-breakpointMin) {
            border-top: 13px solid $primary; // as eea-website
            border-bottom: 1px solid $navItemBorderColor;
        }

        > .container {

            @media (max-width: $grid-float-breakpointMax) {
                padding-right: 0;
                padding-left: 0;
            }

            @media (min-width: $grid-float-breakpointMin) {
                display: flex;
            }
        }

        .nav-link {

            @media (max-width: $grid-float-breakpointMax) {
                display: block;
                width: 100%;
                padding-right: $grid-gutter-width * 0.5;
                padding-left: $grid-gutter-width * 0.5;
                color: #fff;
            }

            &:hover,
            &:active {
                background-color: $navItemHoverColor;
                color: #fff;

                @media (max-width: $grid-float-breakpointMax) {
                    background-color: darken($navItemHoverColor, 10%);
                }
            }
        }

        button.nav-link {
            appearance: none;
            border: 0;
            background: transparent;
            text-transform: uppercase;

            @media (max-width: $grid-float-breakpointMax) {
                text-align: left;
            }
        }
    }

    .navbar-nav {

        @media (max-width: $grid-float-breakpointMax) {
            background-color: $navItemHoverColor;
        }

        + .navbar-nav {

            @media (max-width: $grid-float-breakpointMax) {
                padding-bottom: $spacer2;
            }

            @media (min-width: $grid-float-breakpointMin) {
                margin-left: auto;
            }
        }
    }

    .nav-item {
        line-height: 1;

        @media (min-width: $grid-float-breakpointMin) {
            border-left: 1px solid $navItemBorderColor;
        }

        &:last-child {

            @media (min-width: $grid-float-breakpointMin) {
                border-right: 1px solid $navItemBorderColor;
            }
        }
    }

    .navbar-toggler {
        align-self: stretch;
        width: 3.5rem;
        background-color: $light;
        border-left: 1px solid $navItemBorderColor;

        &:focus {
            box-shadow: unset;
        }

        &[aria-expanded="true"] {
            background-color: $navItemHoverColor;

            .navbar-toggler-icon {
                background-image: escape-svg($navbar-light-toggler-icon-hover-bg);
            }
        }
    }
}
