form {

    .mb-3 + .form-group .btn[type="submit"] {
        margin-top: $spacer3;
    }
}

.form-label {
    text-transform: uppercase;
    font-weight: $font-weight-semibold;
    letter-spacing: $letterSpacing;
}

.form-control {

    &.mediumLarge {
        min-height: 200px;
    }

    &.large {
        min-height: 350px;
    }
}

.altLabels {

    .form-label {
        margin-bottom: 1rem;
        font-size: 100%;
        font-family: $display-font-family;
        font-weight: $font-weight-normal;
        letter-spacing: unset;
        text-transform: unset;
    }
}

div[role="radiogroup"] {

    &.is-invalid {

        .invalid-feedback {
            display: block;
        }

        .form-check-input {
            border-color: $danger;
        }
    }
}
