footer {
    padding-top: $spacer5;

    .footerInner {
        background-color: $dark;
        padding-top: $spacer3;
        padding-bottom: $spacer3;
    }

    .hstack {
        margin-left: -$btn-padding-x;

        @media (max-width: $smMax) {
            flex-direction: column !important;
            align-items: flex-start;
        }
    }

    .btn-link {
        text-decoration: none;
        color: #fff;
    }
}
