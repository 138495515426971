.dropzone {
    background-color: lighten($primary-100, 10%);
    font-family: $display-font-family;

    .dz-default.dz-message {
        margin-top: $spacer3;
        margin-bottom: $spacer4;
        display: flex;
        flex-direction: column;
        color: $gray-800;

        &:before {
            content: "+";
            font-size: 4rem;
            font-weight: $font-weight-bold;
            color: $primary;
        }
    }
}
