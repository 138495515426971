.breadcrumb {
    font-size: $nav-link-font-size;
    text-transform: uppercase;

    .breadcrumb-item {
        display: inline-block;
        padding: $nav-link-padding-y var(--bs-breadcrumb-item-padding-x) $nav-link-padding-y 0;
        letter-spacing: $letterSpacing;

        a {
            text-decoration: none;
        }

    }
}
