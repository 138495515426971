/* source-sans-pro-300 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 300;
    src: url($fontsPath + 'sourcesanspro/source-sans-pro-v21-latin-300.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
    url($fontsPath + 'sourcesanspro/source-sans-pro-v21-latin-300.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* source-sans-pro-300italic - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 300;
    src: url($fontsPath + 'sourcesanspro/source-sans-pro-v21-latin-300italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
    url($fontsPath + 'sourcesanspro/source-sans-pro-v21-latin-300italic.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* source-sans-pro-regular - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    src: url($fontsPath + 'sourcesanspro/source-sans-pro-v21-latin-regular.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
    url($fontsPath + 'sourcesanspro/source-sans-pro-v21-latin-regular.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* source-sans-pro-italic - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 400;
    src: url($fontsPath + 'sourcesanspro/source-sans-pro-v21-latin-italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
    url($fontsPath + 'sourcesanspro/source-sans-pro-v21-latin-italic.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* source-sans-pro-600 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    src: url($fontsPath + 'sourcesanspro/source-sans-pro-v21-latin-600.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
    url($fontsPath + 'sourcesanspro/source-sans-pro-v21-latin-600.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* source-sans-pro-600italic - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 600;
    src: url($fontsPath + 'sourcesanspro/source-sans-pro-v21-latin-600italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
    url($fontsPath + 'sourcesanspro/source-sans-pro-v21-latin-600italic.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* source-sans-pro-700 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    src: url($fontsPath + 'sourcesanspro/source-sans-pro-v21-latin-700.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
    url($fontsPath + 'sourcesanspro/source-sans-pro-v21-latin-700.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* source-sans-pro-700italic - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 700;
    src: url($fontsPath + 'sourcesanspro/source-sans-pro-v21-latin-700italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
    url($fontsPath + 'sourcesanspro/source-sans-pro-v21-latin-700italic.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
