.grid-view {
    margin-top: $spacer5;
    padding-left: 1px;

    .table {
        width: calc(100% - 2px);
    }

    thead {

        th,
        tr {
            border: 0;
        }

        th {
            font-size: $small-font-size;
            font-weight: $font-weight-base;
            white-space: nowrap;

            &,
            * {
                text-decoration: none;
                text-transform: uppercase;
                color: $body-color;
            }

            a {

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        a.asc:after,
        a.desc:after {
            content: '';
            left: 3px;
            display: inline-block;
            width: 0;
            height: 0;
            border: solid 5px transparent;
            margin: 4px 4px 2px 4px;
            background: transparent;
        }

        a.asc:after {
            border-bottom: solid 7px $primary-600;
            border-top-width: 0;
        }

        a.desc:after {
            border-top: solid 7px $primary-600;
            border-bottom-width: 0;
        }
    }

    td {
        line-height: 1.2;
    }

    .actionCol {

        a,
        span {
            @extend .btn;
            @extend .btn-sm;
        }

        a {
            @extend .btn-primary;
        }

        span {
            @extend .disabled;
            cursor: not-allowed;
            pointer-events: unset !important;
            background-color: transparent !important;
            border-color: $gray-500 !important;

            * {
                opacity: 0.85;
            }
        }
    }
}
