.select2-selection {
    box-shadow: none !important;
}

.select2-dropdown {
    border-radius: 0 !important;
}

.select2-search__field {
    background-color: #fff !important;
}
